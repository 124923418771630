@import "global.scss";

body,html{
  width: 100%;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}
#intro{
  display: none;
}
.intro{
  a{
    color: $black;
    &:hover{
      color: $popColor;
    }
  }
}
.btn{
  border: none;
  display: inline-block;
  font-size: 1.2rem;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  svg{
    width: auto; 
    height: 73px;
  }
  &:hover{
    svg{
      fill: #000;
    }
  }
}
#text.over{
  fill: #e91baf;
}
h2{
  margin-top: 0px;
  font-size: 2rem;
}

@media print {
  #title,
  .finished,
  .btn,
  .intro-component{
    display: none;
  }
}

