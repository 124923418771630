@import "global.scss";

.intro{
  @extend .serif;
  color: $introColor;
  margin: 0px $marginSide 0;
  padding-top: 10vh;
  font-size: 1.7rem;
}
.intro-component{
  padding-bottom: 200px;
  .btn{
    margin: 0 auto;
    display: block;
  }
}
.Modal {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(255, 255, 255, 1);
    padding: 50px $marginSide 50px;
    overflow: auto;
    a.close{
      position: fixed;
      right: 50px;
      top: 50px;
    }
    .intro{
      padding-top: 100px;
      margin: 0;
    }
    sup{
      cursor: pointer;
      &:hover{
        color: $popColor;
      }
    }
  }

  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  $link-color: #000;
.menu .burger-icon:before,
.menu .burger-icon:after {
  background-color: $link-color;
}
.menu .burger-icon:before {
  -webkit-transform: translateY(10px) rotate(45deg);
  transform: translateY(10px) rotate(45deg);
}
.menu .burger-icon:after {
  -webkit-transform: translateY(-10px) rotate(-45deg);
  transform: translateY(-10px) rotate(-45deg);
}
.menu hr {
  width: 57%;
  margin: auto;
  background-color: $link-color;
  margin: 7px auto;
  display: block;
}
.menu:hover hr {
  background-color: $link-color;
}
.menu .burger-icon {
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.menu .burger-icon,
.menu .burger-icon:before,
.menu .burger-icon:after {
  display: block;
  width: 45px;
  height: 2px;
  background-color: $link-color;
  transition-property: background-color, -webkit-transform;
  transition-property: background-color, transform;
  transition-duration: 0.3s;
}
.menu .burger-icon:before,
.menu .burger-icon:after {
  position: absolute;
  content: "";
}
.menu .burger-icon:before {
  top: -10px;
}
.menu .burger-icon:after {
  top: 10px;
}

.menu .burger-icon {
  background-color: transparent;
}
