@import "global.scss";

.contract-component{
  margin: 0 $marginSide 0;
  @extend .serif;
  font-size: 1.7rem;
  .bottom{
    display: none;
    border: solid 1px #000;
    padding: 30px;
    margin-top: 80px;
    h4{
      text-transform: uppercase;
      margin: 0px 0px 30px;
      text-align: center;
    }
  }

  .bottom input[type="text"],
  .field{
    border: none;
    border-bottom: 1px solid #000;
    @extend .serif;
    line-height: 1;
    color: $popColor;
  }
  p{
    margin-top: 0px;
    margin-bottom: 2rem;
    &.nb{
      margin-bottom: 0px !important;
    }
    &.indent{
      text-indent: 50px;
    }
    &.tr{
      text-align: right;
    }
    &.full{
      display: flex;
      margin-bottom: 1.5rem;
      input{
        flex-grow: 1;
        margin-left: 10px;
      }
    }
    input{
      outline: none;
      &:focus{
        border-bottom-style: dashed;
      }
      &.day{
        width: 80px;
      }
      &.month{
        width: 120px;
      }
      &.year{
        width: 60px;
      }
      &.collectorSig,
      &.artistSig{
        width: 400px;
      }
      &.collectorAddr,
      &.artistAddr{
        width: 610px;
      }
      &.collector{
        width: 374px;
      }
      &.charityAddr,
      &.charity{
        width: 450px;
      }
    }
  }
  .finished{
    margin: 80px 0 150px;
    position: relative;
    #border{
      stroke: url(#grad1);
      fill: url(#grad1);
    }
    .message{
      position: absolute;
      width: 100%;
      top: 0px;
      left: 0px;
      z-index: 2;
      text-align: center;
      padding-top: 59px;
      font-size: 1.6rem;
      .by{
        color: $popColor;
        font-weight: normal !important;
        font-style: normal !important;
      }
      p{
        margin-bottom: 1.5rem;
        span.work{
          display: block;
          font-style: italic;
        }
        span.artist{
          display: block;
          font-weight: bold;
          font-size: 1.2em;
        }
        &.created{
          font-size: 0.8em;
          padding-top: 30px;
        }
      }
    }
  }
  .printed{
    .name{
      display: none;
    }
  }
  .btn.save{
    display: block;
    margin: auto;

  }
}
@media print{
  .contract-component{
    margin: 80px 0 0;
    font-size: 1rem;
    input.field{
      font-size: 1rem;
      color: #000;
      &.collectorSig,
      &.artistSig{
        color: transparent;
      }
    }
    .bottom{
      display: block;
      page-break-before: always;
    }
    .btn.save{
      display: none;
    }
    .printed{
      display: block;
      .name{
        display: block;
      }
    }
  }
}
