$black: #000;
$introColor: $black;
$marginSide: 20vw;
$popColor: #e91baf;

.sans{
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
}
.serif{
  font-family: Georgia, serif;
  font-size: 1.5rem;
  line-height: 1.7;
}
