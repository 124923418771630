@import "global.scss";

.App {
  padding-bottom: 100px;
  #title {
    width: 100vw;
    height: 100vh;
    position: relative;
    #Layer_1,
    svg{
      width: 100vw;
      height: 100vh;
    }
    #text{
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 2;
    }
  }
}


@media (max-width: 768px) {
  .App{
    .contract-component,
    .intro{
      margin: 0 30px;
      font-size: 1.2rem;
      p{
        input{
          max-width: 100%;
        }
      }
    }
    .contract-component{
      overflow: hidden;
      .finished{
        > svg{
          display: none;
        }
        .message{
          position: relative;
          padding-top: 0px;
        }
      }
    }
  }
  .Modal{
    padding: 0 30px !important;
    .intro{
      font-size: 1.2rem;
    }
  }
}
